import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Modal, Progress, Select, Space, TableProps } from 'antd'
import { EditOutlined, SearchOutlined, UndoOutlined, EyeOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListTransactionTopupsByOrderId, apiCheckTransactionTopupOnAdapter } from '../../api'
import moment from 'moment'
import ButtonBack from '../../components/button/ButtonBack'
import { formatCurrency } from '../../utils/string-utils'
import { useSearchParams } from 'react-router-dom'
import { mbhStatusOptions } from '../../assets/constants/default_options'
import { filterOptionByLabel } from '../../utils/filter-utils'
import ModalView from './ModalView'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { renderStatus } from '.'

export default function ManagementTopUpDetailTransaction() {
    const [tranTopups, setTranTopups] = useState<any>()
    const [progessPercent, setProgessPercent] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalCheckAdapter, setModalCheckAdapter] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [dataCheckAdapter, setDataCheckAdapter] = useState<any>({})

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId')

    const columns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'tranId',
            title: 'Mã giao dịch',
            dataIndex: 'tranId',
            render: tranId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {tranId}
                </span>
            ),
        },
        {
            key: 'customer',
            title: 'Số điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: (price) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'updatedAt',
            title: 'Cập nhật',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    {account.canCheckTransactionTopupOnAdapter && (
                        <Button
                            onClick={() => setModalCheckAdapter({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            type='primary'
                        >
                            Kiểm tra
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionTopupsByOrderId({
                orderId: orderId,
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (resp.data != null) {
                setTranTopups(resp.data)
                const respFull = await apiGetListTransactionTopupsByOrderId({
                    orderId: orderId,
                    page: defaultParams.PAGE,
                    size: defaultParams.MAX_LIMIT,
                })
                if (respFull.data != null) {
                    let totalProgess = 0
                    respFull.data.trans?.map((item: any) => {
                        if (item.status === 'SUCCESS' || item.status === 'FAIL' || item.status === 'ERROR') {
                            totalProgess++
                        }
                    })
                    if (totalProgess > 0) {
                        const percent = Number(totalProgess / respFull.data.total * 100).toFixed(2)
                        setProgessPercent(Number(percent))
                    }
                }
            } else {
                setTranTopups({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, orderId, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCheckAdapter = async () => {
        try {
            setIsLoading(true)
            const resp = await apiCheckTransactionTopupOnAdapter({
                transactionId: modalCheckAdapter.data?.tranId,
            })
            if (resp.status === 200) {
                switch (resp.data?.Data?.TopupStatus) {
                    case 9:
                        resp.data.Message = 'Thành công'
                        break;
                    case 7:
                        resp.data.Message = 'Thất bại'
                        break;
                    case 99:
                        resp.data.Message = 'Chưa rõ kết quả'
                        break;
                    case 0:
                        resp.data.Message = 'Chưa rõ kết quả'
                        break;
                    default:
                        resp.data.Message = 'Không có kết quả trả về'
                        break;
                }
                setDataCheckAdapter(resp.data)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    const handleCloseModalCheckAdapter = () => {
        setModalCheckAdapter({ data: null, isOpen: false })
        setDataCheckAdapter({})
    }

    return (
        <div>
            <ButtonBack />
            <h2>Mã đơn hàng: {orderId}</h2>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start' }}>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={mbhStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <Space style={{ marginBottom: 20 }}>
                Đã xử lý: <Flex gap="small" vertical>
                    <Progress style={{ width: 300 }} percent={progessPercent} status="active" />
                </Flex>
            </Space>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={tranTopups?.trans}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={tranTopups?.total || tranTopups?.trans?.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Kiểm tra giao dịch' open={modalCheckAdapter.isOpen}
                onCancel={handleCloseModalCheckAdapter}
                footer={[
                    <Button onClick={handleCheckAdapter}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalCheckAdapter}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Kiểm tra giao dịch <b>{modalCheckAdapter.data?.tranId}</b></p>
                {dataCheckAdapter?.Message && <b>Kết quả: {dataCheckAdapter.Message}</b>}
                <Divider />
            </Modal>
            <ModalView
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
