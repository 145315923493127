import { formatDate } from '../../utils/entity-utils'
import { Button, Form, Input, InputNumber, Select, Space, TableProps } from 'antd'
import { EyeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import defaultParams, { TRANSACTION_CONNECTION_TYPE_CONNECTION, TRANSACTION_CONNECTION_TYPE_EXTENSION } from '../../assets/constants/default_params'
import { formatCurrency } from '../../utils/string-utils'
import { IPackage, IPartnerPackage, IPartnerPackages } from '../../models/package.modal'
import { apiGetListPackages, apiGetListPartnerPackages } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import CustomTable from '../../components/table'
import ViewPartner from '../package/ViewPartner'
import { transactionConnectionTypeOptions } from '../../assets/constants/default_options'
import { renderType } from '../kit-sim/method'

export default function TabPackage({ cpCode }: any) {
    const [packages, setPackages] = useState<IPartnerPackages>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState<any>({})
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const packageColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: 'STT',
            render: (value, record, index) => ++index + (pageSize * (currentPage - 1)),
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'code',
            title: 'Mã gói cước',
            dataIndex: 'code',
        },
        {
            key: 'price',
            title: 'Giá bán',
            dataIndex: 'price',
            render: (price) => formatCurrency(price || 0),
        },
        {
            key: 'price',
            title: 'Giá trả lại',
            dataIndex: 'price_cashback',
            render: (price_cashback) => formatCurrency(price_cashback || 0),
        },
        {
            key: 'price_discount',
            title: 'Hoa hồng Viettel',
            dataIndex: 'price_discount',
            render: (price_discount) => formatCurrency(price_discount || 0),
        },
        {
            key: 'package',
            title: 'Gói cước',
            render: (_, data: IPartnerPackage) => data.package?.name || Number(data?.package_id),
        },
        {
            key: 'type',
            title: 'Loại gói cước',
            dataIndex: 'type',
            render: type => renderType(type),
        },
        {
            key: 'modify',
            title: 'Cập nhật',
            dataIndex: 'modify',
            render: (modify) => formatDate(modify),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: IPackage) => (
                <Button icon={<EyeOutlined />} onClick={() => setModalView({ data, isOpen: true })}
                    style={{ width: 100, background: '#3e64b5', color: 'white' }}>
                    Chi tiết
                </Button>
            ),
        },
    ]

    const fetchPackages = useCallback(async () => {
        try {
            if (!cpCode) {
                return
            }
            setIsLoading(true)
            const req = {
                limit: pageSize,
                offset: pageSize * (currentPage - 1),
                cpCode: cpCode,
                ...search,
            }
            let resp = { count: 0, partner_packages: [] as any }
            switch (search.type) {
                case TRANSACTION_CONNECTION_TYPE_CONNECTION:
                    const { data: connection } = await apiGetListPartnerPackages(req)
                    resp = connection
                    break;
                case TRANSACTION_CONNECTION_TYPE_EXTENSION:
                    const { data: extension } = await apiGetListPartnerPackages(req)
                    resp = extension
                    break;
                default:
                    const [{ data: conn }, { data: extn }] = await Promise.all([
                        apiGetListPartnerPackages({ ...req, type: TRANSACTION_CONNECTION_TYPE_CONNECTION }),
                        apiGetListPartnerPackages({ ...req, type: TRANSACTION_CONNECTION_TYPE_EXTENSION }),
                    ])
                    resp.count = conn.count + extn.count
                    resp.partner_packages = [...conn.partner_packages, ...extn.partner_packages]
                    break;
            }
            if (resp.count === 0) {
                setPackages({})
                return
            }
            setPackages(resp)
            const { data: packages } = await apiGetListPackages({ limit: defaultParams.MAX_LIMIT })
            if (packages === null) {
                return
            }
            resp.partner_packages = resp.partner_packages.map((partnerPackage: IPartnerPackage) => {
                const packageInfo = packages.packages.find((pack: IPackage) => pack.id === partnerPackage.package_id)
                return {
                    ...partnerPackage,
                    company: { name: cpCode },
                    package: packageInfo,
                }
            })
            setPackages(resp)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize, cpCode, search])

    useEffect(() => {
        fetchPackages()
    }, [fetchPackages])

    const handleSearch = ({ name, code, price, type }: any) => {
        setSearch({ name, code, price, type })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleClearSearch = () => {
        setSearch({})
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 30,
                }}
                form={formSearch} onFinish={handleSearch} id="searchForm" autoComplete="off">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                }}>
                    <Form.Item name='name' label='Tên gói cước' style={{ marginBottom: 0 }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='code' label='Mã gói cước' style={{ marginBottom: 0 }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='price' label='Giá bán' style={{ marginBottom: 0 }}>
                        <InputNumber formatter={value => formatCurrency(Number(value))} style={{ minWidth: 170 }} />
                    </Form.Item>
                    <Form.Item name='type' label='Loại gói cước' style={{ marginBottom: 0 }}>
                        <Select
                            style={{ minWidth: 170 }}
                            options={[
                                { value: '', label: 'Tất cả' },
                                ...transactionConnectionTypeOptions,
                            ]}
                            placeholder="Chọn loại gói cước"
                        />
                    </Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <CustomTable
                namePage='gói cước'
                columns={packageColumns}
                dataSource={packages.partner_packages}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={packages.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <ViewPartner
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
