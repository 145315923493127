import { Button, Form, Select, Space, TableProps, Tag } from 'antd'
import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import defaultParams from '../../assets/constants/default_params'
import CustomTable from '../../components/table'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListUsers } from '../../api'
import { IUser } from '../../models/user.model'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { partnerHistoryOptions } from '../../assets/constants/default_options'

export default function TabHistory({ history }: any) {
    const [userOptions, setUserOptions] = useState<{ label: string, value: Number }[]>([])
    const [feedback, setFeedback] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState<any>({})
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchUsers = useCallback(async () => {
        if (userOptions.length > 0) return
        try {
            const resp = await apiGetListUsers({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.users.map((item: IUser) => ({
                    label: item.username,
                    value: item.username,
                }))
                setUserOptions(options)
            } else {
                setUserOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [userOptions.length])

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    const renderType = (type: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (type === 'CALLING') return <Tag style={style} color="green">Cuộc gọi</Tag>
        if (type === 'GIFTING') return <Tag style={style} color="purple">Quà tặng</Tag>
        if (type === 'MEETING') return <Tag style={style} color="red">Cuộc họp</Tag>
        return
    }

    const historyColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: 'STT',
            render: (value, record, index) => ++index + (pageSize * (currentPage - 1)),
        },
        {
            key: 'ownerBy',
            title: 'Nhân sự',
            dataIndex: 'owner_by',
            render: owner_by => owner_by || 'system'
        },
        {
            key: 'type',
            title: 'Loại tương tác',
            dataIndex: 'sk',
            render: sk => renderType(sk.split('#')[0]),
        },
        {
            key: 'result',
            title: 'Nội dung',
            dataIndex: 'result',
        },
        {
            key: 'dateTime',
            title: 'Thời gian',
            dataIndex: 'sk',
            render: sk => sk.split('#')[1],
        },
    ]

    const fetchHistory = useCallback(async () => {
        try {
            setIsLoading(true)
            let data = { ...history }
            data.calls = history.calls.filter((item: any) => (
                (!search.owner_by || item.owner_by === search.owner_by) &&
                (!search.type || item.sk.split('#')[0] === search.type)
            ))
            data.calls.sort((a: any, b: any) => a.sk.split('#')[1] > b.sk.split('#')[1] ? -1 : 1)
            setFeedback(data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [search, setIsLoading, history])

    useEffect(() => {
        fetchHistory()
    }, [fetchHistory])

    const handleSearch = ({ owner_by, type }: any) => {
        setSearch({ owner_by, type })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleClearSearch = () => {
        setSearch({})
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 30,
                }}
                form={formSearch} onFinish={handleSearch} id="searchForm" autoComplete="off">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                }}>
                    <Form.Item name='owner_by' label='Nhân sự' style={{ marginBottom: 0 }}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn nhân sự"
                            defaultValue={''}
                            options={[
                                { value: '', label: 'Tất cả' },
                                ...userOptions,
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='type' label='Loại tương tác' style={{ marginBottom: 0 }}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại"
                            defaultValue={''}
                            options={[
                                { value: '', label: 'Tất cả' },
                                ...partnerHistoryOptions,
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <CustomTable
                namePage='tương tác'
                columns={historyColumns}
                dataSource={feedback.calls}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={feedback.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}
