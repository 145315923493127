import { Button, ConfigProvider, DatePicker, Divider, Form, Modal, Space, TableProps } from 'antd'
import { EyeOutlined, DashboardOutlined, ApiOutlined, DollarOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT, REVENUE_CONNECTION_AMOUNT_TYPE_REVENUE, STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD, TRANSACTION_CONNECTION_TYPE_CONNECTION, TRANSACTION_CONNECTION_TYPE_EXTENSION } from '../../assets/constants/default_params'
import { formatCurrency, formatNumber } from '../../utils/string-utils'
import { apiGetRevenueConnection, apiGetStatisticalDateConnection, apiGetStatisticalTypeConnection } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import CustomTable from '../../components/table'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import ChartDay from '../../components/chart/ChartDay'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')
const { RangePicker } = DatePicker

export default function TabConnection({ cpCode }: any) {
    const [totalTypes, setTotalTypes] = useState<any>({ dauMoi: 0, giaHan: 0 })
    const [totalProfit, setTotalProfit] = useState<any>({ total: 0, dataPacks: [] })
    const [totalRevenue, setTotalRevenue] = useState<any>({ total: 0, dataPacks: [] })
    const [revenue, setRevenue] = useState<any>([])
    const [totalRecord, setTotalRecord] = useState<any>([])
    const [search, setSearch] = useState<any>({})
    const [defaultSearch, setDefaultSearch] = useState<any>()
    const [modalMonth, setModalMonth] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const handleSearch = (values: any) => {
        const rangeDate = values?.dateConnect
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('YYYY-MM-DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('YYYY-MM-DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }
        setSearch({
            cpCode: cpCode,
            startDate: values.startDate || defaultSearch.startDate,
            endDate: values.endDate || defaultSearch.endDate,
        })
    }

    const handleClearSearch = () => {
        setSearch(defaultSearch)
        formSearch.resetFields()
    }

    const revenueColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: 'STT',
            render: (value, record, index) => ++index,
        },
        {
            key: 'dataPack',
            title: 'Gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'profit',
            title: 'Lợi nhuận',
            dataIndex: 'profit',
            render: (profit) => formatCurrency(profit || 0),
        },
        {
            key: 'revenue',
            title: 'Doanh thu',
            dataIndex: 'revenue',
            render: (revenue) => formatCurrency(revenue || 0),
        },
    ]

    const monthColumns: TableProps<any>['columns'] = [
        {
            key: 'month',
            title: 'Tháng đấu nối',
            dataIndex: 'month',
        },
        {
            key: 'sum',
            title: 'Tổng đấu nối',
            dataIndex: 'sum',
            render: (sum) => formatNumber(sum || 0),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Button icon={<EyeOutlined />} onClick={() => setModalMonth({ data, isOpen: true })}
                    style={{ width: 100, background: '#3e64b5', color: 'white' }}>
                    Chi tiết
                </Button>
            ),
        },
    ]

    const fetchStatistics = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respType, respProfit, respRevenue, respRecord] = await Promise.all([
                apiGetStatisticalTypeConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetRevenueConnection({
                    amountType: REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
                    ...search,
                }),
                apiGetRevenueConnection({
                    amountType: REVENUE_CONNECTION_AMOUNT_TYPE_REVENUE,
                    ...search,
                }),
                apiGetStatisticalDateConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
            ])
            if (respType.data?.types != null) {
                let dauMoi = 0, giaHan = 0
                respType.data.types.map((item: any) => {
                    if (item.type === TRANSACTION_CONNECTION_TYPE_CONNECTION) {
                        dauMoi = item.sum
                    }
                    if (item.type === TRANSACTION_CONNECTION_TYPE_EXTENSION) {
                        giaHan = item.sum
                    }
                    return true
                })
                setTotalTypes({ dauMoi, giaHan })
            } else {
                setTotalTypes({ dauMoi: 0, giaHan: 0 })
            }
            if (respProfit.data !== null) {
                setTotalProfit(respProfit.data)
            } else {
                setTotalProfit({ total: 0, dataPacks: [] })
            }
            if (respRevenue.data !== null) {
                setTotalRevenue(respRevenue.data)
            } else {
                setTotalRevenue({})
            }
            if (respRecord.data?.months !== null) {
                setTotalRecord(respRecord.data.months)
            } else {
                setTotalRecord([])
            }
            if (respRevenue.data?.dataPacks !== null && respProfit.data?.dataPacks !== null) {
                const merged = [...respRevenue.data.dataPacks, ...respProfit.data.dataPacks].reduce((acc, obj) => {
                    const existing = acc.find((item: any) => item.dataPack === obj.dataPack);
                    if (existing) {
                        if ("revenue" in existing) existing.profit = obj.sum;
                        else existing.revenue = obj.sum;
                    } else {
                        acc.push({ dataPack: obj.dataPack, revenue: obj.sum, profit: 0 });
                    }
                    return acc;
                }, []);
                respProfit.data.dataPacks.forEach((obj: any) => {
                    const existing = merged.find((item: any) => item.dataPack === obj.dataPack);
                    if (existing) {
                        existing.profit = obj.sum;
                    } else {
                        merged.push({ dataPack: obj.dataPack, revenue: 0, profit: obj.sum });
                    }
                });
                setRevenue(merged)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, search])

    useEffect(() => {
        const now = new Date()
        const year = now.getFullYear()
        const defaultParams = {
            cpCode: cpCode,
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`,
        }
        setDefaultSearch(defaultParams)
        setSearch(defaultParams)
    }, [cpCode])

    useEffect(() => {
        if (defaultSearch) {
            fetchStatistics()
        }
    }, [fetchStatistics, defaultSearch])

    return (
        <div>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 30,
                }}
                form={formSearch} onFinish={handleSearch} id="searchForm" autoComplete="off">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                }}>
                    {defaultSearch && (
                        <ConfigProvider locale={vi_VN}>
                            <Form.Item name="dateConnect" label="Ngày đấu nối" style={{ marginBottom: 0 }}>
                                <RangePicker
                                    defaultValue={[dayjs(defaultSearch.startDate), dayjs(defaultSearch.endDate)]}
                                    format="YYYY-MM-DD"
                                    picker="date" />
                            </Form.Item>
                        </ConfigProvider>
                    )}
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <ApiOutlined /> Đấu mới: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalTypes.dauMoi)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DashboardOutlined /> Gia hạn: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalTypes.giaHan)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DollarOutlined /> Lợi nhuận: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatCurrency(totalProfit.total)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DollarOutlined /> Doanh thu: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatCurrency(totalRevenue.total)}</span>
                </div>
            </div>
            <Divider />
            <h3>Doanh thu gói cước</h3>
            <CustomTable
                namePage='gói cước'
                columns={revenueColumns}
                dataSource={revenue}
                isHiddenPagination={true}
                total={revenue.length}
            />
            <Divider />
            <h3>Số lượng đấu nối</h3>
            <CustomTable
                namePage='tháng'
                columns={monthColumns}
                dataSource={totalRecord}
                isHiddenPagination={true}
                total={totalRecord.length}
            />
            <Modal
                style={{ minWidth: 800 }}
                title={`Chi tiết tháng ${modalMonth.data?.month} (${formatNumber(modalMonth.data?.sum)} đấu nối)`}
                open={modalMonth.isOpen}
                footer={[]}
                onCancel={() => setModalMonth({ data: null, isOpen: false })}>
                <ChartDay
                    data={[{ month: modalMonth.data?.month, days: modalMonth.data?.days }]}
                />
            </Modal>
        </div>
    )
}
