const defaultParams = {
    PAGE: 1,
    MIN_LIMIT: 10,
    LIMIT: 100,
    MAX_LIMIT: 1000,
}

export const FORMAT_DATE = 'YYYY-MM-DD'

export const TRANSACTION_CONNECTION_TYPE_CONNECTION = "CONNECTION"
export const TRANSACTION_CONNECTION_TYPE_EXTENSION = "EXTENSION"

export const REVENUE_CONNECTION_AMOUNT_TYPE_REVENUE = "REVENUE"
export const REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT = "PROFIT"

export const STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD = "START_DATE_RECORD"
export const STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER = "START_DATE_FILTER"

export default defaultParams