import { useSearchParams } from 'react-router-dom'
import ButtonBack from '../../components/button/ButtonBack'
import { Button, Divider, Form, Input, Modal, Select, Space, Tabs } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { apiAddLogFeedback, apiGetListCallInfo, apiGetListCompanies } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { ICompany } from '../../models/company.modal'
import { COMPANY } from '../../assets/constants/route_path'
import TabConnection from './TabConnection'
import TabPackage from './TabPackage'
import TabHistory from './TabHistory'
import moment from 'moment'
import { toastError, toastSuccess } from '../../utils/toast'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { partnerHistoryOptions } from '../../assets/constants/default_options'
import TabInfo from './TabInfo'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function CompanyDetail() {
    const [searchParams] = useSearchParams()
    const cpCode = searchParams.get('cpCode')
    const [company, setCompany] = useState<ICompany>({})
    const [info, setInfo] = useState({ phone: '', email: '', director: '', address: '' })
    const [listInfo, setListInfo] = useState<any>({})
    const [listHistory, setListHistory] = useState<any>({})
    const [formCreateHistory] = Form.useForm()
    const [isOpenModalCreateHistory, setIsOpenModalCreateHistory] = useState(false)
    const [isOpenModalUpdateInfo, setIsOpenModalUpdateInfo] = useState(false)
    const [formUpdateInfo] = Form.useForm()
    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState

    const fetchCompany = useCallback(async () => {
        try {
            if (!cpCode) {
                return
            }
            setIsLoading(true)
            const resp = await apiGetListCompanies({
                limit: 1,
                code: cpCode,
            })
            if (resp.data === null || resp.data.companies?.length === 0) {
                setCompany({})
                return
            }
            setCompany(resp.data.companies[0])
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, cpCode])

    const fetchInfo = useCallback(async () => {
        try {
            setIsLoading(true)
            const { data } = await apiGetListCallInfo({ pk: `${cpCode}#METADATA` })
            setListInfo(data)
            const partnerInfo = { phone: '', email: '', director: '', address: '' }
            data.calls.map((item: any) => {
                switch (item.sk.split('#')[0]) {
                    case 'PHONE':
                        partnerInfo.phone = item.result
                        break
                    case 'EMAIL':
                        partnerInfo.email = item.result
                        break
                    case 'DIRECTOR':
                        partnerInfo.director = item.result
                        break
                    case 'ADDRESS':
                        partnerInfo.address = item.result
                        break
                }
                return item
            })
            setInfo(partnerInfo)
        } catch (error) {
            console.log(error)
        } finally {
            setTimeout(() => setIsLoading(false), 500)
        }
    }, [setIsLoading, cpCode])

    const fetchHistory = useCallback(async () => {
        try {
            setIsLoading(true)
            const { data } = await apiGetListCallInfo({ pk: `${cpCode}#FEEDBACK-HISTORY` })
            setListHistory(data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, cpCode])

    useEffect(() => {
        fetchCompany()
        fetchInfo()
        fetchHistory()
    }, [fetchCompany, fetchInfo, fetchHistory])

    const handleUpdateInfo = async (values: any) => {
        try {
            setIsLoading(true)
            const { phone, email, director, address } = values
            if (!phone && !email && !director && !address) {
                toastError('Vui lòng nhập thông tin cần cập nhật!')
                return
            }
            if (phone) {
                await apiAddLogFeedback({
                    call: {
                        pk: `${cpCode}#METADATA`,
                        sk: `PHONE#${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                        owner_by: values.ownerBy,
                        result: phone,
                    }
                })
            }
            if (email) {
                await apiAddLogFeedback({
                    call: {
                        pk: `${cpCode}#METADATA`,
                        sk: `EMAIL#${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                        owner_by: values.ownerBy,
                        result: email,
                    }
                })
            }
            if (director) {
                await apiAddLogFeedback({
                    call: {
                        pk: `${cpCode}#METADATA`,
                        sk: `DIRECTOR#${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                        owner_by: values.ownerBy,
                        result: director,
                    }
                })
            }
            if (address) {
                await apiAddLogFeedback({
                    call: {
                        pk: `${cpCode}#METADATA`,
                        sk: `ADDRESS#${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                        owner_by: values.ownerBy,
                        result: address,
                    }
                })
            }
            setIsOpenModalUpdateInfo(false)
            formUpdateInfo.resetFields()
            fetchInfo()
            toastSuccess('Cập nhật thông tin thành công!')
        } catch (error) {
            toastError('Có lỗi xảy ra!')
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCreateHistory = async (values: any) => {
        try {
            setIsLoading(true)
            await apiAddLogFeedback({
                call: {
                    pk: `${cpCode}#FEEDBACK-HISTORY`,
                    sk: `${values.type}#${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                    owner_by: values.ownerBy,
                    result: values.result,
                }
            })
            setIsOpenModalCreateHistory(false)
            formCreateHistory.resetFields()
            fetchHistory()
            toastSuccess('Thêm lịch sử tương tác thành công!')
        } catch (error) {
            toastError('Có lỗi xảy ra!')
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <ButtonBack to={COMPANY} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                <h1>Đối tác: {company.name}</h1>
                {account?.canAddLogFeedback && (
                    <Space>
                        <Button onClick={() => setIsOpenModalCreateHistory(true)}>Thêm tương tác</Button>
                        <Button onClick={() => setIsOpenModalUpdateInfo(true)}>Cập nhật thông tin liên hệ</Button>
                    </Space>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div>
                    <p><b>Tên công ty: </b>{company?.name}</p>
                    <p><b>Mã công ty: </b>{company?.code}</p>
                </div>
                <div>
                    <p><b>Số điện thoại: </b>{info.phone}</p>
                    <p><b>Email: </b>{info.email}</p>
                </div>
                <div>
                    <p><b>Đại diện: </b>{info.director}</p>
                    <p><b>Địa chỉ: </b>{info.address}</p>
                </div>
                <div></div>
                <div></div>
            </div>
            <Divider />
            <Tabs
                size='middle'
                items={[
                    {
                        key: 'connection',
                        label: 'Đấu nối',
                        children: (
                            <TabConnection cpCode={cpCode} />
                        ),
                    },
                    {
                        key: 'package',
                        label: 'Gói cước',
                        children: (
                            <TabPackage cpCode={cpCode} />
                        ),
                    },
                    {
                        key: 'history',
                        label: 'Lịch sử tương tác',
                        children: (
                            <TabHistory history={listHistory} />
                        ),
                    },
                    {
                        key: 'info',
                        label: 'Lịch sử cập nhật thông tin liên hệ',
                        children: (
                            <TabInfo info={listInfo} />
                        ),
                    },
                ]}
            />
            <Modal
                title='Thêm lịch sử tương tác'
                open={isOpenModalCreateHistory}
                footer={null}
                onCancel={() => setIsOpenModalCreateHistory(false)}>
                <Divider />
                <Form form={formCreateHistory} onFinish={handleCreateHistory} layout="vertical">
                    <Space>
                        <Form.Item name='cpCode' label='Công ty' initialValue={cpCode}>
                            <Input disabled value={cpCode || ''} />
                        </Form.Item>
                        <Form.Item name='ownerBy' label='Nhân sự' initialValue={account?.username}>
                            <Input disabled value={account?.username} />
                        </Form.Item>
                    </Space>
                    <Form.Item name='type' label='Loại tương tác'
                        rules={[{ required: true, message: 'Vui lòng chọn loại tương tác!' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại"
                            options={partnerHistoryOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='result' label='Nội dung'
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}>
                        <Input.TextArea />
                    </Form.Item>
                    <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>Thêm</Button>
                    </div>
                </Form>
            </Modal>
            <Modal
                title='Cập nhật thông tin liên hệ'
                open={isOpenModalUpdateInfo}
                footer={null}
                onCancel={() => setIsOpenModalUpdateInfo(false)}>
                <Divider />
                <Form form={formUpdateInfo} onFinish={handleUpdateInfo} labelCol={{ span: 5 }} labelAlign='left'>
                    <Form.Item name='phone' label='Số điện thoại'>
                        <Input placeholder={info.phone} />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input placeholder={info.email} />
                    </Form.Item>
                    <Form.Item name='director' label='Đại diện'>
                        <Input placeholder={info.director} />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input placeholder={info.address} />
                    </Form.Item>
                    <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>Cập nhật</Button>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
